<template>
  <div class="time-box">
    <p class="btnTips">
      <span>按键S:获取开始时间</span><span>按键E:获取结束时间</span
      ><span>按键ESC:取消当前获取时间的操作</span>
      <span>按键A:选中一个片段后，按A进行修改时间操作</span>
    </p>
    <ul class="time-box-list" v-if="timeList.length">
      <li v-for="(item, index) in timeList" :key="index" class="list_li">
        <a-badge
          color="gold"
          @click="openVideo(item)"
          style="cursor: pointer"
        />
        <div :class="index == selected ? 'time-interval selected' : 'time-interval'" @click="clickEditTime(item, index)" @dblclick="openVideo(item)">
          <span>{{ item.start }}</span
          ><span>~</span><span>{{ item.end }}</span>
        </div>
        <a-popconfirm
          title="删除此标记片段？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteTime(index)"
        >
          <a class="delete-time"><a-icon type="close-circle" /></a>
        </a-popconfirm>
      </li>
    </ul>
    <div
      v-if="startTime || endTime"
      class="time-box-tips"
      :style="{ 'margin-top': timeList.length ? '' : '84px' }"
    >
      <a-badge color="red" />
      <span v-if="startTime">{{ "开始时间：" + startTime }}</span>
      <span v-if="endTime">{{ "结束时间：" + endTime }}</span>
    </div>
    <div class="btn-export">
      <a-popconfirm
        :title="'导出'+timeList.length+'个标记的片段'"
        ok-text="确定"
        cancel-text="取消"
        @confirm="exportTime"
      >
        <a-button type="primary">导出片段</a-button>
      </a-popconfirm>
    </div>
    <a-modal
      title="保存为以下经销商"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="
        () => {
          visible = false;
        }
      "
    >
      <p>{{ dealerName }}</p>
    </a-modal>
    <!-- 回放弹窗 -->
    <live-watch
      :visible-children="showLiveWatch"
      @closeModel="close"
      :modal-title="'视频预览'"
      :rang-time="rangTime"
    />
    <editTime
      :visible="showTimeModel"
      :rang-time="selectedTime"
      @closeModel="closeTimeModel"
      @changeRangTime="changeRangTime"
    />
  </div>
</template>
<script>
import api from "@/api/Edit.js";
import liveWatch from "./liveWatch.vue";
import editTime from "./editTime.vue";
export default {
  props: ["mediaIdData", "roomId", "projectId", "dealerName"],
  data() {
    return {
      startTime: "",
      endTime: "",
      timeList: [],
      confirmLoading: false,
      visible: false,
      showLiveWatch: false,
      rangTime: {},
      clickTime: null,
      showTimeModel: false,
      selected: undefined,
      selectedTime: null,
    };
  },
  components: {
    liveWatch,
    editTime
  },
  methods: {
    handleOk() {
      this.exportTimes();
    },
    exportTime() {
      this.visible = true;
    },
    // 键盘事件
    handleKeyup(event) {
      const e = event || window.event || arguments.callee.caller.arguments[0];
      if (!e) return;
      const { key, keyCode } = e;
      let dom = document.getElementsByClassName(
        "TrackContainer_material_block__1K0T1"
      );
      if (keyCode === 83 || keyCode === 69) {
        let positionJudge = this.judgeExcursion();
        if (!dom.length) {
          this.$message.error("请先添加视频至剪辑区！");
          return;
        }
        if (positionJudge) {
          this.$message.error("视频剪辑区不允许偏移！");
          return;
        }
      }
      let timeTips = document.getElementsByClassName(
        "toolbarTime_current__2Piog"
      )[0].innerHTML;
      switch (keyCode) {
        case 83:
          this.judgeRangTime(timeTips, 0);
          break;
        case 69:
          this.judgeRangTime(0, timeTips);
          break;
        case 27:
          console.log("按下了esc键");
          this.escKeyCOde()
          break;
        case 65:
          this.editTime();
          break;
        default:
          break;
      }
    },
    // 按下esc键取消
    escKeyCOde() {
      this.startTime = "";
      this.endTime = "";
    },
    // 开始结束时间比较
    judgeRangTime(start, end) {
      if (start) this.startTime = start;
      if (end) this.endTime = end;
      if (this.startTime && this.endTime) {
        let timeE = this.timeToSec(this.endTime);
        let timeS = this.timeToSec(this.startTime);
        if (timeE > timeS) {
          let timeObj = {
            start: this.startTime,
            end: this.endTime,
          };
          this.timeList.push(timeObj);
          this.startTime = "";
          this.endTime = "";
        } else {
          this.$message.warning("开始时间需大于结束时间!");
        }
      }
    },
    /**
     * 时间转为秒
     * @param time 时间(00:00:00)
     * @returns {string} 时间戳（单位：豪秒）
     */
    timeToSec(time) {
      if (!time) return;
      var s = "";
      var hour = time.split(":")[0];
      var min = time.split(":")[1];
      var sec = time.split(":")[2];
      s = (Number(hour * 3600) + Number(min * 60) + Number(sec)) * 1000;
      return s;
    },
    // 删除时间段
    deleteTime(index) {
      this.timeList.splice(index, 1);
    },
    exportTimes() {
      this.confirmLoading = true;
      let arr = [];
      this.timeList.forEach((item) => {
        let start = this.timeToSec(item.start) / 1000;
        let end = this.timeToSec(item.end) / 1000;

        arr.push({
          start,
          end,
        });
      });
      let data = {
        fragments: arr,
        media_id: sessionStorage.getItem("mediaId"),
        project_id: this.projectId,
        room_id: this.roomId,
      };
      api
        .exportTimes(data)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("导出成功！");
            this.timeList = [];
            this.visible = false;
          } else {
            this.$message.error("导出失败！");
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    // 判断偏移
    judgeExcursion() {
      let childDom = document.getElementsByClassName(
        "TrackContainer_material_block__1K0T1"
      );
      let judge = [];
      childDom.forEach((element) => {
        let left = element.style.left;
        judge.push(left);
      });
      return judge.indexOf("0px") == -1;
    },
    // 关闭弹窗
    close() {
      this.showLiveWatch = false;
    },
    // 打开弹窗
    openVideo(value) {
      clearTimeout(this.clickTime)

      let start = this.timeToSec(value.start) / 1000;
      let end = this.timeToSec(value.end) / 1000;
      this.rangTime = {
        start,
        end,
      };
      this.showLiveWatch = true;
    },
    //单击编辑时间
    clickEditTime(item, index) {
      let that = this
      clearTimeout(that.clickTime)
      that.clickTime = setTimeout(() => {
        this.selected = this.selected === index ? undefined : index
      }, 300)
    },
    //编辑时间
    editTime() {
      if(this.selected || this.selected === 0) {
        this.showTimeModel = true
        this.selectedTime = this.timeList[this.selected]
      }
    },
    closeTimeModel() {
      this.selectedTime = null
      this.showTimeModel = false
    },
    changeRangTime(data) {
      this.closeTimeModel()
      this.timeList[this.selected] = data
      console.log(data)
    }
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.handleKeyup);
  },
};
</script>
<style lang="less" scoped>
ul,
ol {
  list-style: none;
}
.time-box {
  position: relative;
  //   top:0;
  //   left:0;
  min-width: 400px;
  background: #0b0b0b;
  font-size: 16px;
  // height: 100%;
  color: #ededef;
  &-list {
    padding-top: 10px;
    max-height: 68%;
    overflow: auto;
    margin-top: 80px;
    .list_li {
      display: flex;
      margin-bottom: 12px;
      .delete-time {
        // background: #ededef;
        font-size: 18px;
        font-weight: 900;
        margin-left: 10px;
        color: #ededef;
        :hover {
          color: red;
        }
      }
      .time-interval {
        padding-left: 6px;
        cursor: pointer;
        span {
          margin-right: 6px;
        }
        &.selected {
          background-color: #333;
        }
      }
    }
  }
  &-tips {
    font-size: 14px;
    padding-left: 40px;
    display: flex;
    // margin-top: 28px;
    span {
      margin-right: 6px;
    }
  }
}

.btn-export {
  position: fixed;
  top: 10px;
  right: 40px;
}
.btnTips {
  position: absolute;
  font-size: 14px;
  padding: 8px;
  margin: 0;
  span {
    display: inline-block;
    margin-right: 24px;
  }
}
</style>
<style>
.ant-badge-status-dot {
  width: 12px;
  height: 12px;
}
</style>