<template>
  <div>
    <div id="video-editor" class="video-editor"></div>
    <!-- 媒资弹层 -->
    <a-modal
      v-model="isModalVisible"
      :title="modalTitle"
      okText="导入"
      :confirmLoading="isConfirmLoading"
      @ok="handleOk"
    >
      <template>
        <a-table
          :columns="columns"
          :data-source="mediaList"
          rowKey="mediaId"
          :pagination="false"
          :scroll="{ y: 350 }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <template slot="operation" slot-scope="text, item">
            <a :href="item.inputUrl" target="_blank">查看</a>
          </template>
        </a-table>
      </template>
    </a-modal>
    <!-- 生成导出视频 -->
    <a-modal
      v-model="accomplishVisible"
      title="导出视频"
      @ok="saveVideo"
      :confirmLoading="isConfirmLoading"
    >
      <a-form-model
        ref="videoForm"
        :model="videoForm"
        class="search-form"
        v-bind="layout"
        :rules="rules"
      >
        <a-form-model-item label="文件名" prop="name">
          <a-input
            placeholder="请输入主播文件名"
            v-model.trim="videoForm.name"
          />
        </a-form-model-item>
        <a-form-model-item label="分辨率">
          <a-select v-model="videoForm.classify">
            <a-select-option :value="el" v-for="el in classifyList" :key="el">
              {{ el }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="码率">
          <a-input placeholder="请输入码率" v-model.trim="videoForm.code" />
        </a-form-model-item>
        <a-form-model-item label="描述">
          <a-textarea :rows="4" v-model="videoForm.describe" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import _ from "lodash";
import api from "@/api/Edit.js";
import TimeDom from "./components/TimeDom";
import Vue from "vue";
const columns = [
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "素材类型",
    dataIndex: "mediaType",
    customRender: (text) => {
      const map = {
        image: "图片",
        video: "视频",
        audio: "音频",
        text: "文字",
      };
      let name = map[text] || "未知";
      return name;
    },
  },
  {
    title: "业务类型",
    dataIndex: "businessType",
    customRender: (text) => {
      const map = {
        subtitles: "字幕",
        watermark: "水印",
        opening: "片头/开场",
        ending: "片尾",
        general: "通用 (默认)",
        sticker: "贴纸",
      };
      let name = map[text] || "未知";
      return name;
    },
  },
  {
    title: "操作",
    dataIndex: "inputUrl",
    scopedSlots: {
      customRender: "operation",
    },
  },
];

export default {
  components: {
    TimeDom,
  },
  data() {
    return {
      projectId: this.$route.query.projectId,
      isModalVisible: false,
      modalTitle: "导入素材",
      columns,
      mediaList: [],
      selectedRowKeys: [],
      selectedMedia: [],
      isConfirmLoading: false,
      accomplishVisible: false,
      classifyList: [
        "640*360",
        "960*540",
        "1280*720",
        "1920*1080",
        "2560*1440",
      ],
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 17 },
      },
      videoForm: {
        name: "",
        classify: "",
        code: "",
        describe: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入文件名称", trigger: "change" },
        ],
      },
      roomId: this.$route.query.roomId,
      // timeline: "",
      // startTime: "",
      // endTime: "",
      timeList: [],
    };
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedMedia = this.mediaList.filter((item) => {
        return selectedRowKeys.indexOf(item.mediaId) > -1;
      });
      console.log(this.selectedMedia);
    },
    handleOk() {
      // 绑定mediaId
      if (this.selectedRowKeys.length > 0) {
        this.isConfirmLoading = true;
        let obj = {};
        this.selectedMedia.forEach((item) => {
          if (obj[item.mediaType]) {
            obj[item.mediaType] += `,${item.mediaId}`;
          } else {
            obj[item.mediaType] = item.mediaId;
          }
        });
        api
          .postProjectMaterials({
            ProjectId: this.projectId,
            MaterialMaps: obj,
          })
          .then((res) => {
            if (res.length > 0) {
              this.addMediaResolve(res);
            } else {
              this.$message.error("导入失败");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$message.error(err);
          })
          .finally(() => {
            this.isConfirmLoading = false;
          });
      } else {
        this.$message.warning("请选择素材");
      }
    },
    // 生成视频
    saveVideo() {
      console.log(1213);
      this.$refs.videoForm.validate((valid) => {
        if (valid) {
          this.isConfirmLoading = true;
          let width = "";
          let height = "";
          if (this.videoForm.classify) {
            width = this.videoForm.classify.split("*")[0];
            height = this.videoForm.classify.split("*")[1];
          }
          const ProjectMetadata = {
            Description: this.videoForm.describe,
          };
          let output = {
            // MediaURL: '',
            Width: width,
            Height: height,
            Bitrate: this.videoForm.code,
          };
          api
            .postMediaProductingJob({
              Timeline: JSON.stringify(this.timeline),
              ProjectId: this.projectId,
              FileName: this.videoForm.name,
              RoomId: this.roomId,
              ProjectMetadata: JSON.stringify(ProjectMetadata),
              OutputMediaConfig: JSON.stringify(output),
            })
            .then((res) => {
              if (res.code === 200) {
                this.isConfirmLoading = false;
                this.accomplishVisible = false;
                this.$confirm({
                  title: "是否跳转列表页查看生成的视频？",
                  content: (h) => <div style="color:red;">成功合成视频！</div>,
                  onOk: () => {
                    this.$router.push({
                      path: "/console/list",
                      query: {
                        roomId: this.roomId,
                      },
                    });
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                  class: "test",
                });
              }
            });
        }
      });
    },
    addMediaResolve() {},
  },
  mounted() {
    console.log('projectId',this.projectId)
    window.AliyunVideoEditor.init({
      container: document.getElementById("video-editor"),
      locale: "zh-CN",
      produceEditingProjectVideo: ({ coverUrl, duration = 0, timeline }) => {
        return new Promise((resolve) => {
          console.log("生成", resolve, "时间线", timeline);
          this.accomplishVisible = true;
          this.timeline = timeline;

          // callDialog({
          //   // 调用方需要自己实现提交合成任务的界面，这里的 callDialog 只是一种示例
          //   onSubmit: async ({
          //     title,
          //     description,
          //   }) => {
          //     // 假设提交合成任务的界面让你获得了这些数据
          //     const ProjectMetadata = {
          //         Title: title,
          //       Description: description,
          //     }
          //     const res = await api.postMediaProductingJob({
          //       Timeline: timeline,
          //       ProjectId: this.projectId,
          //       ProjectMetadata: JSON.stringify(ProjectMetadata),
          //       OutputMediaConfig: {
          //         // MediaURL: '',
          //         FileName: '',
          //         Width: '',
          //         Height: '',
          //         Bitrate: ''
          //       }
          //     })
          //   },
          // });
        });
      },
      // 获取贴纸分类
      // getStickerCategories: async () => {
      // },
      // 保存编辑工程时间线
      updateEditingProject: ({ coverUrl, duration, FEExtend, isAuto }) =>
        new Promise((resolve, reject) => {
          api
            .putEditingProject({
              ProjectId: this.projectId,
              CoverURL: coverUrl,
              Duration: duration,
              FEExtend: JSON.stringify(FEExtend),
            })
            .then((res) => {
              if (res.data.body.RequestId) {
                if (!isAuto) {
                  this.$message.success("保存成功");
                }
                resolve({
                  projectId: this.projectId,
                });
              } else {
                reject();
              }
            });
        }),
      // 获取正在编辑工程时间线
      getEditingProject: async () => {
        const res = await api.getEditingProject({
          // Action: "GetEditingProject",
          project_id: this.projectId,
        });
        const FEExtendString = res.Project.FEExtend;
        const timelineString = res.Project.Timeline;

        return {
          projectId: this.projectId,
          timeline: timelineString ? JSON.parse(timelineString) : undefined,
          FEExtend: FEExtendString ? JSON.parse(FEExtendString) : undefined,
          modifiedTime: res.Project.ModifiedTime,
        };
      },
      // 删除素材
      deleteEditingProjectMaterials: (mediaId, mediaType) => {
        return api.deleteEditingProjectMaterials({
          ProjectId: this.projectId,
          MaterialType: mediaType,
          MaterialIds: mediaId,
        });
      },
      // 获取剪辑工程关联素材
      getEditingProjectMaterials: () => {
        if (this.projectId) {
          return api.getProjectMaterialsInfo({
            project_id: this.projectId,
          });
        } else {
          return Promise.resolve([]);
        }
      },
      // 动态获取播放地址
      useDynamicSrc: true,
      getDynamicSrc: (mediaId, mediaType) => {
        // this.mediaIdData = mediaId
        sessionStorage.setItem('mediaId', mediaId);
        let url = api.getMediaInfo({ media_id: mediaId })
        console.log(url);
        url.then(res => {sessionStorage.setItem('videoUrl', res)})
        return url;
      },

      // 唤起导入素材弹层
      searchMedia: (mediaType) => {
        console.log(mediaType);
        return new Promise((resolve) => {
          // 获取公共媒资列表
          api
            .getPublicMediaInfo({
              Action: "ListMediaBasicInfos",
              MaxResults: 20,
              MediaType: mediaType,
            })
            .then((res) => {
              console.log(res);
              const mediaList = _.get(res, "data.body.MediaInfos", []);
              const list = mediaList.map((item) => {
                const info = item.MediaBasicInfo || {};
                return {
                  inputUrl: info.InputURL,
                  coverURL: info.CoverURL,
                  title: info.Title,
                  mediaId: info.MediaId,
                  mediaType: info.MediaType,
                  createTime: info.CreateTime,
                  modifiedTime: info.ModifiedTime,
                  businessType: info.BusinessType,
                  status: info.Status,
                };
              });
              this.mediaList = list;
              this.isModalVisible = true;
              this.addMediaResolve = resolve;
            });
        });
      },
    });

    // let dom ='<div class="time-box">'
    //   +'<ul class="time-box-list" v-if="\''+this.timeList.length+'\">'
    //     +'<li v-for="\''+(item, index) in this.timeList+'\" :key="\''+index+'\" class="list_li">'
    //       +'<a-badge color="gold" /><span>{{ \''+item.start+'\}}</span><span>~</span><span>{{ \''+item.end+'\}}</span>'
    //       +'<a-popconfirm title="删除此标记片段？" ok-text="确定" cancel-text="取消" @confirm="\''+deleteTime(index)+'\">'
    //         +'<a class="delete_time"><a-icon type="close-circle" /></a>'
    //       +'</a-popconfirm>'
    //     +'</li>'
    //   +'</ul>'
    //   +'<div v-if="\''+this.startTime || this.endTime+'\" class="time-box-tips">'
    //     +'<a-badge color="red" />'
    //     +'<span v-if="\''+this.startTime+'\">{{ "开始时间：" \''+ this.startTime+'\ }}</span>'
    //     +'<span v-if="\''+this.endTime+'\">{{ "结束时间：" \''+ this.endTime +'\ }}</span>'
    //   +'</div>'
    // +'</div>';
    let Dom = Vue.extend(TimeDom);

    this.$nextTick(() => {
      document.getElementsByClassName(
        "MaterialSidebar_tab__10WbH"
      )[2].style.display = "none";

      document.getElementsByClassName(
        "MaterialContainer_button__2sgJa"
      )[0].style.display = "none";

      document.getElementsByClassName(
        "HeaderToolBar_save_button__2Qzba"
      )[0].style.visibility = "hidden";

      document.getElementsByClassName(
        "HeaderToolBar_produce_button__38Qpj"
      )[0].style.visibility = "hidden";

      let parentDom = document.getElementsByClassName(
        "views_bodyRight__22-Sz"
      )[0];
      // console.log('this.mediaIdData',this.mediaIdData)
      new Dom(
        {
        propsData: {
          // mediaIdData:this.mediaIdData,
          roomId:this.roomId,
          projectId:this.projectId,
          dealerName:this.$route.query.dealerName
        },
      }
      ).$mount(parentDom);
    });
    window.addEventListener("keyup", this.handleKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.handleKeyup);
  },
};
</script>

<style lang="less" scoped>
.video-editor {
  height: 100vh;
}
.views_bodyRight__22-Sz {
  position: relative;
}
</style>
