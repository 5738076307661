<template>
  <a-modal
    :visible="visibleChildren"
    :title="modalTitle"
    :footer="null"
    @cancel="closeModal"
    :width="650"
  >
    <div style="width: 600px; height: 400px">
      <video
        :src="liveUrl"
        id="media"
        autoplay
        style="
          width: calc(100% - 2px);
          height: calc(100% - 12px);
          border-radius: 10px;
        "
        controls="controls"
      />
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    visibleChildren: {
      type: Boolean,
      required: false,
      default: false,
    },
    rangTime: {
      type: Object,
    },
    modalTitle: {
      type: String,
    },
  },

  data() {
    return {
      player: null,
      liveUrl: undefined
      // liveUrl: sessionStorage.getItem("videoUrl"),
    };
  },
  created() {},
  mounted() {
  },
  watch: {
    rangTime() {
      console.log(11);
      this.$nextTick(()=>{
        this.liveUrl = sessionStorage.getItem("videoUrl")
        console.log(this.liveUrl);
        let media = document.getElementById("media");
        media.currentTime = this.rangTime.start;
        media.addEventListener('timeupdate',() =>{
          if(media.currentTime>=this.rangTime.end){
            media.pause()
          }
        })
      })

    },
  },
  methods: {
    callbackfun(e) {
      console.log("callbackfun", e);
    },

    closeModal() {
      this.$emit("closeModel");
    },
  },
};
</script>
