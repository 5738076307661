<template>
  <a-modal
    :visible="visible"
    title="时间编辑"
    @cancel="closeModal"
    :width="650"
  >
    <template slot="footer">
      <a-button @click="closeModal">取消</a-button>
      <a-button type="primary" @click="onSubmit">确定</a-button>
    </template>
    <div class="time-box">
      <div class="time">
        <a-input-number v-model="startTime[0]" class="input" :min="0" :precision="0" />
        <span>:</span>
        <a-input-number v-model="startTime[1]" class="input" :min="0" :max="59" :precision="0" />
        <span>:</span>
        <a-input-number v-model="startTime[2]" class="input" :min="0" :max="59.99" :precision="2" />
      </div>
      <div class="rang">~</div>
      <div class="time">
        <a-input-number v-model="endTime[0]" class="input"  :min="0" :precision="0" />
        <span>:</span>
        <a-input-number v-model="endTime[1]" class="input" :min="0" :max="59" :precision="0" />
        <span>:</span>
        <a-input-number v-model="endTime[2]" class="input" :min="0" :max="59.99" :precision="2" />
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    rangTime: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      startTime: [],
      endTime: []
    };
  },
  created() {},
  mounted() {
  },
  watch: {
    rangTime: {
			handler: function(val) {
        console.log(val)
				if(val) {
          this.startTime = val.start.split(':')
          this.endTime = val.end.split(':')
        }
			},
			immediate: true,
      deep: true
    },
  },
  methods: {
    closeModal() {
      // this.startTime = []
      // this.endTime = []
      this.$emit("closeModel");
    },
    onSubmit() {
      // 开始结束时间比较
      if (this.startTime && this.endTime) {
        let timeE = this.timeToSec(this.endTime);
        let timeS = this.timeToSec(this.startTime);
        if (timeE > timeS) {
          let rangTime = {
            start: this.toTime(this.startTime),
            end: this.toTime(this.endTime),
          }
          this.$emit('changeRangTime', rangTime)
        } else {
          this.$message.warning("结束时间需大于开始时间!");
        }
      } else {
        this.$message.warning("请选择时间!");
      }
    },
    toTime(time) {
      let str = ''
      time.map((item, index) => {
        if(index != 0) {
          str += `:${item}`
        } else {
          str = item
        }
      })
      return str
    },
    /**
      * 时间转为秒
      * @param time 时间(00:00:00)
      * @returns {string} 时间戳（单位：豪秒）
      */
    timeToSec(time) {
      if (!time) return;
      var s = "";
      var hour = time[0];
      var min = time[1];
      var sec = time[2];
      s = (Number(hour * 3600) + Number(min * 60) + Number(sec)) * 1000;
      return s;
    },
  },
};
</script>

<style lang="less" scoped>
.time-box {
  display: flex;
  align-items: center;
  .rang {
    width: 40px;
    text-align: center;
  }
  span {
    display: inline-block;
    width: 20px;
    text-align: center;
  }
  .input {
    width: 80px;
  }
}
</style>